import _ from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import arkadu from "src/coreConfig";
import animationData from "src/lotties/arkadu_city.json";
import { clearCurrentCustomer, setCustomerByName } from "src/slices/customers";
import { resetGlobals } from "src/slices/global";
import { saveSettings } from "src/slices/settings";
import {
  clearTaxesError,
  setCurrentLicense,
  setUpdatedTaxes,
} from "src/slices/taxes";
import { loadUserFromStorage } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NotAuthorizedView from "src/views/errors/NotAuthorizedView";
import LoadingScreen from "./LoadingScreen";
import LottieAnimation from "./LottieAnimation";

const AuthGuard = ({ children }) => {
  const { user, isAuthenticated, loaded } = useSelector((state) => state.users);
  const { adminMode } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const { currentCustomer, customerSections, customersList } = useSelector(
    (state) => state.customers
  );

  const { t } = useTranslation();
  const { motorError } = useSelector((state) => state.motor);
  const { taxesError } = useSelector((state) => state.taxes);
  const { customerName } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();

  const [viewState, setViewState] = useState({
    lastCustomer: null,
    checkingPerms: true,
    membership: false,
    moduleEnabled: null,
  });

  useEffect(() => {
    if (currentCustomer && customerSections && customerSections.length > 0) {
      var _clearPath = location.pathname.replace(/\//g, "");
      var _path = _clearPath;
      if (currentCustomer) {
        _path = _clearPath.replace(currentCustomer.shortname, "");
      }
      if (_clearPath === currentCustomer.shortname) {
        setViewState((prev) => ({
          ...prev,
          moduleEnabled: true,
        }));
        return;
      }

      var enabled_paths = ["monitorappointments", "app", "AdminDashboard"];
      enabled_paths.map((_p) => {
        if (_path.startsWith(_p)) {
          setViewState((prev) => ({
            ...prev,
            moduleEnabled: true,
          }));
          return false;
        }
      });

      _.forEach(customerSections, (_s) => {
        if (
          currentCustomer &&
          (location.pathname.includes(
            `/${currentCustomer.shortname}${_s.href}`
          ) ||
            location.pathname.includes(`/${currentCustomer.shortname}/admin/`))
        ) {
          setViewState((prev) => ({
            ...prev,
            moduleEnabled: true,
          }));
          return false;
        }
      });
    }
  }, [currentCustomer, customerSections]);

  useEffect(() => {
    var hostname = window.location.hostname;

    if (hostname !== "arkadu.com" && !currentCustomer && customersList) {
      var _customer = _.find(
        customersList,
        (_c) => _c.custom_domain === hostname
      );

      if (_customer) {
        dispatch(setCustomerByName({ shortname: _customer.shortname }));
      }
    }
  }, [location, customersList, currentCustomer]);

  useEffect(() => {
    if (!currentCustomer && customerName) {
      dispatch(setCustomerByName({ shortname: customerName }));
    }
  }, [customerName, currentCustomer]);

  useEffect(() => {
    if (location.pathname.startsWith("/app")) {
      dispatch(saveSettings({ adminMode: false }));
      dispatch(clearCurrentCustomer({ licenses: null }));
      dispatch(setUpdatedTaxes({ licenses: null }));
      dispatch(setCurrentLicense(null));
      dispatch(resetGlobals());
    }

    if (location.pathname.endsWith("/login")) {
      handleAuthRedirect();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!user) {
      dispatch(loadUserFromStorage());
    }
  }, [user]);

  useEffect(() => {
    if (motorError) {
      enqueueSnackbar(motorError, { variant: "error" });
    }
  }, [motorError]);
  useEffect(() => {
    if (taxesError) {
      enqueueSnackbar(taxesError, { variant: "error" });
      dispatch(clearTaxesError());
    }
  }, [taxesError]);

  const checkUserMembership = useCallback(() => {
    if (!currentCustomer || !user) {
      return;
    }

    if (!user.member_set) {
      return false;
    }

    user.member_set.map((m, k) => {
      if (m.customer_id === currentCustomer.id) {
        setViewState((prev) => ({
          ...prev,
          membership: true,
          checkingPerms: false,
        }));
        return true;
      }
      if (k === user.member_set.length - 1) {
        setViewState((prev) => ({
          ...prev,
          membership: false,
          checkingPerms: false,
        }));
        if (adminMode) {
          dispatch(saveSettings({ adminMode: false }));
        }
        return false;
      }
    });
  }, [currentCustomer, user, adminMode]);

  useEffect(() => {
    if (currentCustomer && currentCustomer.id !== viewState.lastCustomer) {
      setViewState((prev) => ({
        ...prev,
        lastCustomer: currentCustomer.id,
      }));
    } else {
      setViewState((prev) => ({
        ...prev,
        lastCustomer: null,
      }));
    }
  }, [currentCustomer]);

  const handleAuthRedirect = () => {
    var url = `https://${arkadu.hostname}/auth`;
    if (customer_id) {
      url += `?customer_id=${customer_id}&client_id=${arkadu.client_id}`;
    }
    window.location.replace(url, "_self");
  };

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      // handleAuthRedirect();
    }
  }, [isAuthenticated, loaded]);

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      if (!window.location.pathname.endsWith("login"))
        localStorage.setItem("next", window.location.pathname);
      // history.push("/login");
    }
  }, [isAuthenticated, loaded]);

  useEffect(() => {
    if (!location.pathname.includes("/admin")) {
      setViewState((prev) => ({
        ...prev,
        checkingPerms: false,
      }));
      return;
    }

    if (user && currentCustomer && location.pathname.includes("/admin")) {
      checkUserMembership();
    }
  }, [currentCustomer, user, location.pathname]);

  if (customerName && !currentCustomer) {
    return <LottieAnimation animationData={animationData} size="60%" />;
  }

  if (viewState.moduleEnabled === null) {
    return <LoadingScreen message={t("comprobando módulo")} />;
  }

  if (currentCustomer && !viewState.moduleEnabled) {
    return (
      <NotAuthorizedView
        message={t("El cliente no tiene habilitado este módulo")}
      />
    );
  }

  if (viewState.checkingPerms || !loaded) {
    return <LoadingScreen message={t("verificando permisos")} />;
  }

  if (!isAuthenticated && loaded) {
    return <Redirect to={customerName ? `/${customerName}/login` : "/login"} />;
  }

  if (
    user &&
    !user.is_completed &&
    location.pathname !== "/app/account/registration"
  ) {
    return <Redirect to="/app/account/registration" />;
  }

  if (location.pathname.includes("/admin") && !viewState.membership) {
    if (!location.pathname.includes("/corporate/admin/")) {
      var newPath = location.pathname.replace("/admin", "");
      history.push(newPath);
      return <NotAuthorizedView />;
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
